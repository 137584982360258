<template>
  <div
    class="col-md-12"
    style="max-width:790px;max-height:1100px;"
  >
    <div
      class="card"
      style="border: none"
      id="print"
    >
      <div class="card-body">

        <div class="row">
          <div class="col-12">
            <table border="1" style="width:100%" >
              <tr>
                <td v-for="index in alphabeth" :key="index" class="text-center" style="width:10px">
                  <span class="font-weight-bolder">{{index}}</span>
                </td>
              </tr>
            </table>
          </div>
          <div class="col-md-3">
              <table class="font-weight-bold">
                <tr>
                  <td style="width: 80px; padding: 5px">Nomor : </td>
                  <td>{{data.patient_id}}</td>
                </tr>
              </table>
          </div>
          <div class="col-md-6 d-flex justify-content-center">
              <img src="/images/hayandra.jpeg" width="300" class="mt-8" alt="Logo" />
          </div>
          <!-- <div class="col-md-4">
            Notes
            <div class="form-group">
              <label for="notes">Alergi:</label>
              <textarea
                name=""
                id="notes"
                cols="30"
                rows="3"
                class="form-control"
                style="resize: none"
                v-model="data.allergy"
                readonly
              ></textarea>
            </div>
          </div> -->
        </div>
        <div class="row mb-1">
          <div class="col-md-6 col-lg-6 col-sm-6 col-6">
            <table class="font-weight-bold">
              <tr>
                <td style="width: 200px; padding: 5px">Nama </td>
                <td>: {{ data.patient_name }}</td>
              </tr>
              <tr>
                <td style="width: 200px; padding: 5px">Umur / Tanggal Lahir </td>
                <td>: {{ data.age +" / "+ data.display_birt_date}}</td>
              </tr>
              <tr>
                <td style="width: 200px; padding: 5px">Jenis Kelamin </td>
                <td>: {{ data.gender = "male" ? "Laki-laki" : "Perempuan" }}</td>
              </tr>
              <tr>
                <td style="width: 200px; padding: 5px">Nama Orang Tua </td>
                <td>: {{ data.parent_name}}</td>
              </tr>
            </table>
          </div>
          <div class="col-md-6 col-lg-6 col-sm-6 col-6">
           <table class="font-weight-bold">
                  <tr>
                    <td style="width: 70px; padding: 5px">Alamat </td>
                    <td>: {{ data.address }}</td>
                  </tr>
                  <tr>
                    <td style="width: 70px; padding: 5px">Telepon </td>
                    <td>: {{ data.mobile_phone}}</td>
                  </tr>
                  <tr>
                    <td style="width: 70px; padding: 5px">Alergi </td>
                    <td>: {{ data.allergy}}</td>
                  </tr>
                </table>
          </div>
        </div>
        <div
          class="row justify-content-center"
          style="font-size: 12px;"
        >

          <b-table
            striped
            hover
            :items="items"
            :fields="fields"
          >
            <template #table-colgroup="scope">
              <col
                v-for="field in scope.fields"
                :key="field.key"
                :style="{ width: field.key === 'title' ? '40%' : '' }"
              />
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  props: {
    data: Object,
    items: Object,
  },

  data() {
    return {
      alphabeth: [],
      fields: [
        {
          key: "display_date",
          label: "Tanggal",
        },
        {
          key: "title",
          label: "Uraian(S O A P)",
        },
        {
          key: "case",
          label: "Instruksi",
        },
        {
          key: "doctor_name",
          label: "Dokter",
        },
      ],

    }
  },

  methods:{
    loopAlhabeth(){
      	//set the default value of i & j to print A to Z
	      var i = 65;
	      var j = 91;
        let k

	      //loop through the values from i to j
	      for(k = i; k < j; k++){
	      	//convert the char code to string (Alphabets)
	      	this.alphabeth.push(String.fromCharCode(k)) ;
	      }
        console.log("alpha",this.alphabeth);
  }

  },
  mounted(){
    this.loopAlhabeth()
  }

}
</script>

<style>
</style>